import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import React from 'react';
import Consent from '../shared/Consent';

function Tesla({
    SITE_URL,
    CONNECT_ACTIVATE_URI,
}) {
    const { path } = useRouteMatch();
    const history= useHistory();

    return (
        <Switch>
            <Route exact path={path}>
                    <Consent
                        accountName='Tesla'
                        onClick={ () => history.push('/tesla/consented') }
                        mileometer
                        gps
                    />
            </Route>
            <Route path={`${path}/consented`}>
                {/* intentionally blank */}
            </Route>
        </Switch>
    );
}

export default Tesla;

import React from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../App';
import { useState } from 'react';
import { useEffect } from 'react';
import Consent from '../shared/Consent';
import images from '../shared/images';
import Spinner from '../shared/Spinner';
import { Success } from '../shared/OauthResult';
import { ButtonPrimary } from '@by-miles/bymiles-styles';
import { Link } from 'react-router-dom';

export default function BMW({ CONNECT_ACTIVATE_URI }) {
    const BMW_ACTIVATE_URI = `${CONNECT_ACTIVATE_URI}/activate/bmw`
    const [state, setState] = useState({ type: 'loading' });
    const { policyReference } = useContext(UserContext);
    const [consent, setConsent] = useState(false);
    const requestEmail = () => {
        requestApproval(BMW_ACTIVATE_URI, policyReference)
            .then(() => setState({ type: 'pending' }))
            .catch((error) => setState({ type: 'error', error }));
    };

    useEffect(() => {
        checkStatus(BMW_ACTIVATE_URI, policyReference)
            .then((status) => setState({ type: status }))
            .catch((error) => setState({ type: 'error', error }));
    }, [BMW_ACTIVATE_URI, policyReference]);

    if (!consent) {
        return <Consent
            accountName='BMW'
            onClick={ setConsent }
            mileometer
        />;
    }
    switch (state.type) {
        case 'loading':
            return Loading();
        case 'pending':
            return PendingApproval(requestEmail);
        case 'approved':
            return <Success />;
        case 'new':
            return RequestApproval(requestEmail);
        case 'error':
            return Error(state.error, policyReference);
        default:
            return Error(new Error('An unexpected error has occurred'), policyReference);
    }
}

function Loading() {
    return <div className="bymiles-connect">
        <h1>Connect your BMW.</h1>
        <p>In order for us to count your miles, we need your car to start talking to us. We are checking the status of your BMW now.</p>
        <Spinner message='Checking status of your BMW....' />
    </div>;
}

function RequestApproval(requestEmail) {
    return (<>
        <div className="bymiles-connect">
            <h1>Connect your BMW.</h1>
            <p>In order for us to count your miles, we need your car to start talking to us. You will shortly receive an email from BMW asking for your approval.</p>
            <div className="bymiles-connect-oem">
                <span><img src={ images.bmwLogo } alt="" /></span>
                <span><img src={ images.connectLogo } alt="" /></span>
                <span><img src={ images.bLogo } alt="" /></span>
            </div>
        </div>
        <ButtonPrimary
            onClick={ requestEmail }
            label="Request BMW email"
            stretch={ true }
            iconRight="IconCaretRight"
        />
    </>);
}

function PendingApproval(requestEmail) {
    return <>
        <div className="bymiles-connect">
            <h1>Check your email.</h1>
            <p>You should have received an email from BMW, asking you to grant permission to By Miles to access your car.
                Please follow the instructions in the email.</p>
            <p>Alternatively, you can log in to your BMW
                Connected Drive account to grant By Miles permission from there.
            </p>
            <div className="bymiles-connect-oem">
                <span><img src={ images.bmwLogo } alt="" /></span>
                <span><img src={ images.connectLogo } alt="" /></span>
                <span><img src={ images.bLogo } alt="" /></span>
            </div>
        </div>
        <ButtonPrimary
            onClick={ requestEmail }
            label="Send email again"
            stretch={ true }
            iconRight="IconCaretRight"
        />
    </>
}

function Error(err, policyReference) {
    return <>
        <div className="bymiles-error">
            <h1>Oh dear! This isn't right.</h1>
            <p>{err?.message ? err.message : `An unexpected error has occurred.`}</p>
        </div>
        <Link to={ `/?policyRef=${ policyReference }` }>
            <ButtonPrimary
                label="Try again"
                iconRight="IconCaretRight"
                stretch={ true }
            />
        </Link>
    </>
}

function checkStatus(BMW_ACTIVATE_URI, policyReference) {
    return axios
        .get(`${BMW_ACTIVATE_URI}/${policyReference}/check`, { policyReference })
        .then((resp) => {
            switch (resp.status) {
                case 202:
                    return 'pending';
                case 200:
                    return 'approved';
                default:
                    return 'new';
            }
        })
        .catch((error) => {
            if (error.response.status === 404) {
                return 'new';
            }
            throw error;
        });
}

function requestApproval(BMW_ACTIVATE_URI, policyReference) {
    return axios.post(`${BMW_ACTIVATE_URI}`, { policyReference });
}

import axios from 'axios';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

export const useQuerystring = (...params) => {
    const querystring = new URLSearchParams(useLocation().search);
    return params.reduce((output, nextParam) => {
        return {
            ...output,
            [nextParam]: querystring.get(nextParam),
        }
    }, {});
}

export const useActivateVehicle = (authorizationCode, policyReference, authUrl, redirectURI) => {
    const [loading, setLoading] = useState(false);
    const [outcome, setOutcome] = useState({
        success: false,
        message: 'The authorisation code is missing from the querystring.',
    });
    useEffect(() => {
        const persistCode = async () => {
            setLoading(true);
            let {success, message} = outcome;
            try {
                const response = await axios.post(authUrl, {
                    redirectURI,
                    policyReference,
                    authorizationCode,
                }, {
                    crossDomain: true
                });
                if (response.status === 204) {
                    success = true;
                    message = 'You\'re connected!';
                } else {
                    message = response.message;
                }
            } catch (err) {
                message = err.message;
            }
            setOutcome({success, message});
            setLoading(false);
        }
        if (authorizationCode && policyReference) {
            persistCode();
        }
    }, []);
    return [outcome, loading];
}

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { UserContext } from '../App';
import React, { useContext, useState } from 'react';
import { useQuerystring, useActivateVehicle } from '../shared/utils';
import OauthResult from '../shared/OauthResult';
import Consent from '../shared/Consent';
import { ButtonPrimary } from '@by-miles/bymiles-styles';
import images from '../shared/images';

function Porsche({
    SITE_URL,
    CONNECT_ACTIVATE_URI,
    HIGH_MOBILITY_APP_ID,
    HIGH_MOBILITY_CLIENT_ID,
    HIGH_MOBILITY_OAUTH_URI,
}) {
    const { path } = useRouteMatch();
    const redirectURL = `${SITE_URL}${path}/result`;
    const { code } = useQuerystring('code');
    const { policyReference } = useContext(UserContext);
    const [response, loading] = useActivateVehicle(code, policyReference, `${CONNECT_ACTIVATE_URI}/activate/highmobility/porsche`, redirectURL);
    const [consent, setConsent] = useState(false);
    const highMobilityUrlParams = {
        app_id: HIGH_MOBILITY_APP_ID,
        client_id: HIGH_MOBILITY_CLIENT_ID,
        redirect_uri: redirectURL,
        state: policyReference,
    };

    return (
        <Switch>
            <Route exact path={path}>
                { !consent ?
                    <Consent
                        accountName='Porsche'
                        onClick={ setConsent }
                        mileometer
                    />
                    :
                    <>
                        <div className="bymiles-connect">
                            <h1>Connect your car.</h1>
                            <p>In order for us to count your miles, we need your car to start talking to us. Please connect your manufacturer account.</p>
                            <div className="bymiles-connect-oem">
                                <span><img src={ images.porscheLogo } alt="" /></span>
                                <span><img src={ images.connectLogo } alt="" /></span>
                                <span><img src={ images.bLogo } alt="" /></span>
                            </div>
                        </div>
                        <ButtonPrimary
                            onClick={ () => {
                                window.location = `${HIGH_MOBILITY_OAUTH_URI}?${new URLSearchParams(highMobilityUrlParams)}`
                            } }
                            label="Log in to Porsche"
                            stretch={ true }
                            iconRight="IconCaretRight"
                        />
                    </>
                }
            </Route>
            <Route path={`${path}/result`}>
                <OauthResult response={response} loading={loading} />
            </Route>
        </Switch>
    );
}

export default Porsche;

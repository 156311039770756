
import bLogo from '../media/images/b-logo.png';
import connectLogo from '../media/images/connect.png';
import mercedesLogo from '../media/logos/Mercedes.svg';
import porscheLogo from '../media/logos/porsche.svg';
import bmwLogo from '../media/logos/bmw-logo-grey.svg';
import fordLogo from '../media/logos/ford.png';
import helloImage from  '../media/images/hello.svg';
import successImage from  '../media/images/success.svg';
import miniLogo from '../media/logos/MINI.svg';
import teslaLogo from '../media/logos/tesla.svg';
import spinner from '../media/images/spinner.png';

export default {
    bLogo,
    connectLogo,
    mercedesLogo,
    porscheLogo,
    bmwLogo,
    fordLogo,
    helloImage,
    successImage,
    miniLogo,
    teslaLogo,
    spinner,
}

import './App.scss';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import React, { createContext } from 'react';
import BMW from './providers/BMW';
import MINI from './providers/MINI';
import Tesla from './providers/Tesla';
import Porsche from './providers/Porsche';
import Mercedes from './providers/Mercedes';
import Hello from './routes/hello';
import Feed from './routes/feed';

const querystring = new URLSearchParams(window.location.search);
const policyReference = querystring.get('state');
export const UserContext = createContext({ policyReference });

const COMMON_SETTINGS = {
    SITE_URL: process.env.REACT_APP_SITE_URL,
    CONNECT_ACTIVATE_URI: process.env.REACT_APP_CONNECT_ACTIVATE_URI,
};

const PORSCHE_SETTINGS = {
    ...COMMON_SETTINGS,
    HIGH_MOBILITY_APP_ID: process.env.REACT_APP_HIGH_MOBILITY_APP_ID,
    HIGH_MOBILITY_CLIENT_ID: process.env.REACT_APP_HIGH_MOBILITY_CLIENT_ID,
    HIGH_MOBILITY_OAUTH_URI: process.env.REACT_APP_HIGH_MOBILITY_OAUTH_URI,
};

const MERCEDES_SETTINGS = {
    ...COMMON_SETTINGS,
    MERCEDES_OAUTH_URI: process.env.REACT_APP_MERCEDES_OAUTH_URI,
    MERCEDES_CLIENT_ID: process.env.REACT_APP_MERCEDES_CLIENT_ID,
};

const BMW_SETTINGS = {
    ...COMMON_SETTINGS,
};

export const App = () => (
    <UserContext.Provider value={{ policyReference }}>
        <Router>
            <UserContext.Consumer>
                {({ policyReference }) => (
                    <>
                        {policyReference ? (
                            <Switch>
                                <Route path="/feed">
                                    <Feed />
                                </Route>
                                <Route path="/mercedes">
                                    <section className='bymiles-content'>
                                        <Mercedes {...MERCEDES_SETTINGS} />
                                    </section>
                                </Route>
                                <Route path="/porsche">
                                    <section className='bymiles-content'>
                                        <Porsche {...PORSCHE_SETTINGS} />
                                    </section>
                                </Route>
                                <Route path="/bmw">
                                    <section className='bymiles-content'>
                                        <BMW {...BMW_SETTINGS} />
                                    </section>
                                </Route>
                                <Route path="/mini">
                                    <section className='bymiles-content'>
                                        <MINI />
                                    </section>
                                </Route>
                                <Route path="/tesla">
                                    <section className='bymiles-content'>
                                        <Tesla />
                                    </section>
                                </Route>
                                <Route path="/">
                                    <section className='bymiles-content'>
                                        <Hello />
                                    </section>
                                </Route>
                            </Switch>
                        ) : (
                            <p>Cannot proceed without user details</p>
                        )}
                    </>
                )}
            </UserContext.Consumer>
        </Router>
    </UserContext.Provider>
);

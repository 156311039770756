import { Link } from 'react-router-dom';
import images from '../shared/images';


const Hello = () => {
    return (
        <div className="bymiles-hello">
            <img src={ images.helloImage } alt="" />
            <h1>Hello.</h1>
            <p>Welcome to your fresh new pay-by-mile policy. Get activated and on the road in a few simple steps. Choose your vehicle to get started.</p>
            <nav>
                <p className="oem-logo"><Link to="/mercedes">
                    <img src={ images.mercedesLogo } width="150" height="150" alt="Connect your Mercedes Benz" />
                </Link></p>
                <p className="oem-logo"><Link to="/porsche">
                    <img src={ images.porscheLogo } width="200" height="200" alt="Connect your Porsche" />
                </Link></p>
                <p className="oem-logo"><Link to="/bmw">
                    <img src={ images.bmwLogo } width="120" height="120" alt="Connect your BMW" />
                </Link></p>
                <p className="oem-logo"><Link to="/mini">
                    <img src={ images.miniLogo } width="120" height="120" alt="Connect your MINI" />
                </Link></p>
                <p className="oem-logo"><Link to="/tesla">
                    <img src={ images.teslaLogo } width="150" alt="Connect your Tesla" />
                </Link></p>
                <p className="oem-logo"><Link to="/bmw">
                    <img src={ images.fordLogo } width="150" alt="Connect your Ford" />
                </Link></p>
            </nav>
        </div>
    );
}

export default Hello;

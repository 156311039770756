import React from 'react';
import { ButtonPrimary } from '@by-miles/bymiles-styles';
import mileometerReading from '../media/images/icon-odometer.png';
import gpsPosition from '../media/images/icon-position.png';

export default (props) => {
    return (
        <>
            <div className="bymiles-permissions">
                <h1>Manage your permissions.</h1>
                <p>In order for you policy to work, we need to read the following things from your { props.accountName } account:</p>
                { props.mileometer &&
                    <span className="bymiles-permission">
                        <img src={ mileometerReading } alt="" />
                        Mileometer reading
                    </span>
                }
                { props.gps &&
                    <span className="bymiles-permission">
                        <img src={gpsPosition} alt=""/>
                        GPS Position
                    </span>
                }
                <p>Read our privacy policy for the full list of what data we use, how we use it and how we keep it secure.</p>
            </div>
            <ButtonPrimary
                onClick={ () => props.onClick(true) }
                label="I accept"
                stretch={ true }
                iconRight="IconCaretRight"
            />
        </>
    );
}

import React from 'react';
import images from './images';

export default props => {
    return (
        <>
            <span className="bymiles-spinner">
                <img src={ images.spinner } width="40" height="40" alt="Loading" />
            </span>
            <p>{ props.message }</p>
        </>
    );
}

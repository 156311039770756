import { Link } from 'react-router-dom';
import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../App';
import images from './images';
import Spinner from '../shared/Spinner';
import { ButtonPrimary } from '@by-miles/bymiles-styles';

export const Success = () => {
    return (<>
        <div className='bymiles-success'>
            <img className="artwork" src={ images.successImage } alt="" />
            <h1>Hurray! You're activated.</h1>
            <p>After your first day of driving, details of your miles and the cost will appear, making managing your car (and your spending) easier than ever.</p>
        </div>
        <Link to="/feed">
            <ButtonPrimary
                label="Let's go"
                iconRight="IconCaretRight"
                stretch={ true }
            />
        </Link>
    </>);
};

export default props => {
    const { policyReference } = useContext(UserContext);
    const [loading, setLoading] = useState(props.loading);
    const [response, setResponse] = useState(props.response);
    useEffect(() => {
        setLoading(props.loading);
        setResponse(props.response);
    }, [props]);
    return (
        <>
            { loading ?
                <div className="bymiles-connect">
                    <h1>Connect your car.</h1>
                    <p>In order for us to count your miles, we need your car to start talking to us. Please connect your manufacturer account.</p>
                    <Spinner message="Waiting for connection..." />
                </div>
                :
                <>
                    { response?.success ?
                        <Success />
                        :
                        <>
                            <div className="bymiles-error">
                                <h1>Oh dear! This isn't right.</h1>
                                <p>{ response.message }</p>
                            </div>
                            <Link to={ `/?policyRef=${ policyReference }` }>
                                <ButtonPrimary
                                    label="Try again"
                                    iconRight="IconCaretRight"
                                    stretch={ true }
                                />
                            </Link>
                        </>
                    }
                </>
            }
        </>
    )
}

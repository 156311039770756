import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { UserContext } from '../App';
import React, { useContext, useState } from 'react';
import { ButtonPrimary } from '@by-miles/bymiles-styles';
import { useQuerystring, useActivateVehicle } from '../shared/utils';
import OauthResult from '../shared/OauthResult';
import Consent from '../shared/Consent';
import images from '../shared/images';

function Mercedes({ SITE_URL, MERCEDES_OAUTH_URI, MERCEDES_CLIENT_ID, CONNECT_ACTIVATE_URI }) {
    const { path } = useRouteMatch();
    const redirectUrl = `${SITE_URL}${path}/result`;
    const { policyReference } = useContext(UserContext);
    const { code } = useQuerystring('code');
    const [response, loading] = useActivateVehicle(code, policyReference, `${CONNECT_ACTIVATE_URI}/activate/mercedes`, redirectUrl);
    const [consent, setConsent] = useState(false);

    const mercedesUrlParams = {
        response_type: 'code',
        client_id: MERCEDES_CLIENT_ID,
        redirect_uri: redirectUrl,
        scope: 'mb:vehicle:mbdata:payasyoudrive offline_access',
        state: policyReference,
    };

    return (
        <Switch>
            <Route exact path={path}>
                { !consent ?
                    <Consent
                        onClick={ setConsent }
                        accountName='Mercedes.me'
                        mileometer
                    />
                    :
                    <>
                        <div className="bymiles-connect">
                            <h1>Connect your car.</h1>
                            <p>In order for us to count your miles, we need your car to start talking to us. Please connect your manufacturer account.</p>
                            <div className="bymiles-connect-oem">
                                <span><img src={ images.mercedesLogo } alt="" /></span>
                                <span><img src={ images.connectLogo } alt="" /></span>
                                <span><img src={ images.bLogo } alt="" /></span>
                            </div>
                        </div>
                        <ButtonPrimary
                            onClick={ () => {
                                window.location = `${MERCEDES_OAUTH_URI}?${new URLSearchParams(mercedesUrlParams)}`
                            } }
                            label="Log in to mercedes.me"
                            stretch={ true }
                            iconRight="IconCaretRight"
                        />
                    </>
                }
            </Route>
            <Route path={`${path}/result`}>
                <OauthResult response={response} loading={loading} />
            </Route>
        </Switch>
    );
}

export default Mercedes;
